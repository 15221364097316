import React from "react"
import Reusable from "../../components/articleLayout"
import LTCIcon from "../../images/ltc.png"
import LTCCard from "../../images/TwitterCards/LTCCard.png"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const EarnCrypto = () => (
  <Layout>
    <SEO
      title="Earn Unlimited Litecoin on Earncrypto"
      description="Learn how to earn unlimited Litecoin on Earncrypto."
      article={true}
      image={LTCCard}
      pathname="/crypto/earncrypto"
    />
    <Reusable
      CoinbaseRef={false}
      Logo={LTCIcon}
      ImageAlt="LTC Logo"
      From="www.earncrypto.com"
      Title="Earn Unlimited Litecoin on Earncrypto"
      Name="Litecoin"
      Ticker="LTC"
      Value="Unlimited"
      Address="https://www.earncrypto.com/earn-free-litecoin/?r=113583"
      Step1="Click the “Claim Now” button on this page."
      Step2="You will be redirected to Earncrypto.com"
      Step3="Scroll down and click on the Start Earning Litecoin Now! button."
      Step4="Fill out your information and then click Sign Up Now."
      Step5="Confirm you email address."
      Step6="You are ready to go, start earning crypto in your free time!"
      Introduction="Earncrypto.com is an awesome way to make some passive crypto! This site allows you to do various task in exchange for crypto, for instance Watching Videos, Taking Surveys, Downloading Apps, Playing Games, you name it. For every task you complete you will be rewarded in points, those points are then rewarded to you in the cryptocurrency of your choice. They currently offer 67 different coins to choose from. In this tutorial I will be using LTC but you can change your coin preference after signing up."
    />
  </Layout>
)

export default EarnCrypto
