import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import BraveBanner from "../components/braveBanner"

const ArticleLayout = ({
  Logo,
  Title,
  Value,
  Ticker,
  Address,
  From,
  Step1,
  Step2,
  Step3,
  Step4,
  Step5,
  Step6,
  Step7,
  Step8,
  Step9,
  Step10,
  Step11,
  Step12,
  Step13,
  Step14,
  Step15,
  Name,
  Introduction,
  Introduction2,
  CoinbaseRef,
  ImageAlt,
}) => (
  <Main>
    {/* <BraveBanner /> */}
    <SmallAdd>
      <iframe
        title="Title"
        data-aa="1323916"
        src="//ad.a-ads.com/1323916?size=320x50"
        scrolling="no"
        style={{
          width: 320,
          height: 50,
          border: 0,
          padding: 0,
          overflow: "hidden",
          borderRadius: 5,
        }}
        allowtransparency="true"
      />
    </SmallAdd>
    <BigAdd>
      <iframe
        title="BigAd"
        data-aa="1326309"
        src="//ad.a-ads.com/1326309?size=990x90"
        scrolling="no"
        style={{
          width: 990,
          height: 90,
          border: 0,
          padding: 0,
          overflow: "hidden",
          borderRadius: 5,
        }}
        allowtransparency="true"
      />
    </BigAdd>
    <Box>
      <LeftColumn>
        <BackContainer>
          <BackButton to="/">{"< "}Back</BackButton>
        </BackContainer>
        <LogoContainer alt={ImageAlt} src={Logo} />
        <ValueText>{Value}</ValueText>
        <NameText>
          {Name} {Ticker}
        </NameText>
        <FromText>{From}</FromText>

        <ClaimButton onClick={() => window.open(Address, "_blank")}>
          <ButtonText>Claim Now</ButtonText>
        </ClaimButton>
      </LeftColumn>
      <RightColumn>
        <ArticleTitle>{Title}</ArticleTitle>
        <IntroductionText>{Introduction}</IntroductionText>
        {Introduction2 !== undefined && (
          <IntroductionText>{Introduction2}</IntroductionText>
        )}
        <HowToTitle>Instructions:</HowToTitle>
        {Step1 !== undefined && (
          <StepText>
            <Bolder>1.</Bolder> {Step1}
          </StepText>
        )}
        {CoinbaseRef === true && (
          <StepText>
            <Bolder>2.</Bolder> If you do not yet have a Coinbase account, click{" "}
            <Link
              style={{ textDecoration: "underline" }}
              to="/crypto/bitcoin-btc-$10"
            >
              here
            </Link>{" "}
            to sign up.
          </StepText>
        )}
        {Step2 !== undefined && (
          <StepText>
            <Bolder>2.</Bolder> {Step2}
          </StepText>
        )}
        {Step3 !== undefined && (
          <StepText>
            <Bolder>3.</Bolder> {Step3}
          </StepText>
        )}
        {Step4 !== undefined && (
          <StepText>
            <Bolder>4.</Bolder> {Step4}
          </StepText>
        )}
        {Step5 !== undefined && (
          <StepText>
            <Bolder>5.</Bolder> {Step5}
          </StepText>
        )}
        {Step6 !== undefined && (
          <StepText>
            <Bolder>6.</Bolder> {Step6}
          </StepText>
        )}
        {Step7 !== undefined && (
          <StepText>
            <Bolder>7.</Bolder> {Step7}
          </StepText>
        )}
        {Step8 !== undefined && (
          <StepText>
            <Bolder>8.</Bolder> {Step8}
          </StepText>
        )}
        {Step9 !== undefined && (
          <StepText>
            <Bolder>9.</Bolder> {Step9}
          </StepText>
        )}
        {Step10 !== undefined && (
          <StepText>
            <Bolder>10.</Bolder> {Step10}
          </StepText>
        )}
        {Step11 !== undefined && (
          <StepText>
            <Bolder>11.</Bolder> {Step11}
          </StepText>
        )}
        {Step12 !== undefined && (
          <StepText>
            <Bolder>12.</Bolder> {Step12}
          </StepText>
        )}
        {Step13 !== undefined && (
          <StepText>
            <Bolder>13.</Bolder> {Step13}
          </StepText>
        )}
        {Step14 !== undefined && (
          <StepText>
            <Bolder>14.</Bolder> {Step14}
          </StepText>
        )}
        {Step15 !== undefined && (
          <StepText>
            <Bolder>15.</Bolder> {Step15}
          </StepText>
        )}
      </RightColumn>
    </Box>
  </Main>
)

export default ArticleLayout

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`

const BigAdd = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px;
  transition-delay: 0.05s;
  transition-duration: 0.3s;
  @media only screen and (min-width: 20px) and (max-width: 1100px) {
    display: none;
  }
`

const SmallAdd = styled.div`
  display: none;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  transition-delay: 0.05s;
  transition-duration: 0.3s;
  @media only screen and (min-width: 20px) and (max-width: 1100px) {
    display: flex;
  }
`

const Box = styled.div`
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  width: 60%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 100px;
  transition-delay: 0.05s;
  transition-duration: 0.3s;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
  padding: 20px;
  @media only screen and (min-width: 320px) and (max-width: 820px) {
    width: 95%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
`

const LogoContainer = styled.img`
  width: 100px;
  height: 100px;
  margin: 20px;

  @media only screen and (min-width: 320px) and (max-width: 820px) {
    width: 80px;
    height: 80px;
  }
`

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 60%;
  height: 100%;
  padding: 20px;
  @media only screen and (min-width: 320px) and (max-width: 820px) {
    width: 100%;
    margin-top: 20px;
  }
`

const Bolder = styled.span`
  font-weight: bold;
  color: black;
`

const StepText = styled.span`
  font-family: proxima-nova, Avenir, sans-serif;
  font-size: 14px;
  font-weight: 200;
  color: rgba(69, 75, 92, 1);
  text-align: left;
  margin-left: 10px;
  margin-bottom: 6px;
  @media only screen and (min-width: 320px) and (max-width: 820px) {
  }
`

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 40%;
  @media only screen and (min-width: 320px) and (max-width: 820px) {
    width: 100%;
  }
`

const BackContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
`

const BackButton = styled(Link)`
  font-family: proxima-nova, Avenir, sans-serif;
  font-size: 20px;
  color: rgb(0, 0, 0, 0.6);
  text-align: left;
  @media only screen and (min-width: 320px) and (max-width: 820px) {
    font-size: 18px;
  }
`

const ArticleTitle = styled.h1`
  font-family: proxima-nova, Avenir, sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: rgb(69, 75, 92);
  text-align: left;
  margin-bottom: 10px;
  @media only screen and (min-width: 320px) and (max-width: 820px) {
    font-size: 18px;
  }
`

const NameText = styled.span`
  font-family: proxima-nova, Avenir, sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: rgb(69, 75, 92);
  text-align: left;
  @media only screen and (min-width: 320px) and (max-width: 820px) {
  }
`

const FromText = styled.span`
  font-family: proxima-nova, Avenir, sans-serif;
  font-size: 14px;
  font-weight: bold;
  color: rgba(69, 75, 92, 0.4);
  text-align: left;
  margin-bottom: 10px;
  @media only screen and (min-width: 320px) and (max-width: 820px) {
  }
`

const ValueText = styled.span`
  font-family: proxima-nova, Avenir, sans-serif;
  font-size: 26px;
  font-weight: bold;
  color: #85bb65;
  text-align: left;
  margin-bottom: 10px;
  @media only screen and (min-width: 320px) and (max-width: 820px) {
  }
`

const HowToTitle = styled.span`
  font-family: proxima-nova, Avenir, sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: rgb(69, 75, 92);
  text-align: left;
  margin-bottom: 10px;
  @media only screen and (min-width: 320px) and (max-width: 820px) {
  }
`

const IntroductionText = styled.span`
  font-family: proxima-nova, Avenir, sans-serif;
  font-size: 14px;
  // font-weight: bold;
  color: rgba(69, 75, 92, 0.8);
  text-align: left;
  margin-bottom: 10px;
  @media only screen and (min-width: 320px) and (max-width: 820px) {
  }
`

const ClaimButton = styled.div`
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  aling-items: center;
  justify-content: center;
  padding: 10px;
  background-color: rgba(22, 82, 240, 1);
`

const ButtonText = styled.span`
  font-family: proxima-nova, helvetica neue, Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 18px;
  color: white;
`
